import React, { MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import HomeBannerImage from '../../assets/images/banner-image.png';
import AboutusCircleImage from "../../assets/images/about-us-section-circle-image.png"
function HomeAboutus() {
    const navigate = useNavigate();

    // Handle the click event on the "Start your free trial" link
    const handleStartTrialClick = (event: MouseEvent<HTMLAnchorElement>) => {
        const auth = getAuth(); // Get the current auth instance
        if (!auth.currentUser) {
            event.preventDefault(); // Prevent default link behavior if not logged in
            navigate('/login'); // Redirect to the login page
        } else {
            // If the user is logged in, allow the link to navigate to /views
            navigate('/views'); // Redirect to views page
        }
    };

    return(
        <section className="about-us-section">
            <div className="about-us-section-background-right-side-circle-image">
                <img src={AboutusCircleImage} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-us-inner-section">
                            <div className="about-us-text">
                                <h2  id="about-us">ABOUT US</h2>
                                <p>We understand the challenges of being an educator. The countless hours spent outside the classroom, meticulously crafting lesson plans, searching for engaging resources, and trying to stay ahead of the curve. We want to change that with Praro.ai. Praro comes from the word,  Praroh which in Sanskrit means, growing. Learning signifies growing and with Praro we want to keep evolving how learning and education works. Our vision is to offer a digital space for teachers powered by their expertise and technology where they can effortlessly create and share high-quality instructional materials tailored to their unique needs.</p>
                            </div>
                            <div className="about-us-section-btn">
                                <Link className="section-btn" to="/views" onClick={handleStartTrialClick}>Start your free trial &nbsp;<i className="fa-solid fa-arrow-right-long"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeAboutus;