import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../Form/ContactForm";
import ContactusImage from '../../assets/images/get-in-touch-section-image.png'
function HomeContactus(){
    return(
        <section className="contact-us-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-us-section-image">
                            <img src={ContactusImage} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-us-right-section">
                            <div className="contact-us-right-section-title">
                                <h2 id="contact-us">GET IN TOUCH</h2>
                                <p>Got ideas for new features? Feedback on what we're already doing? Or just want to say hello? We're all ears! Let's connect on <Link to="/" className="text-btn">support@praro.ai</Link></p>
                            </div>
                            <div className="contact-from-field-section">
                                <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeContactus;