import React, { createContext, useContext, useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

interface AuthContextType {
  app: FirebaseApp | null;
  user: User | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  app: FirebaseApp;
  user: User | null;
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ app, user, children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(user);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, [app]);

  return <AuthContext.Provider value={{ app, user: currentUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};